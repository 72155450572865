<template>
  <!--begin::Dashboard-->
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-xl-8">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-3 mb-3">
              {{ $t("modals.pollster.userAssignment") }}
            </div>
            <!--end::Title-->
          </div>
          <!--end::Heading-->

          <!--begin::Card body-->
          <div class="card-body p-9 pt-1 ps-6 pb-1">
            <div class="tab-content">
              <div class="tab-pane fade show active">
                <Form
                  id="vaccinate_modal_form"
                  :validation-schema="form"
                  class="form"
                  @submit="inviteUser"
                >
                  <div class="row mb-2">
                    <div class="col-md-9">
                      <div class="fv-row mb-10">
                        <label class="form-label fs-6 fw-bolder text-dark">
                          {{ $t("modals.pollster.email") }}:
                        </label>

                        <!--begin::Input-->
                        <Field
                          class="
                            form-control form-control-md form-control-solid
                          "
                          type="text"
                          name="emails"
                          autocomplete="off"
                          :placeholder="$t('modals.pollster.enterEmail')"
                          v-model="emailInput"
                          @input="handleEmailInput"
                          @keydown.enter.prevent
                        />
                        <div class="form-text text-muted">
                          {{ $t("modals.pollster.enterEmailDesc") }}
                        </div>
                        <!--end::Input-->

                        <div class="d-flex flex-wrap mt-2">
                          <button
                            v-for="(email, index) in emailList"
                            :key="index"
                            class="btn btn-secondary me-2 mb-2"
                            @click="removeEmail(index)"
                          >
                            {{ email }} <i class="fas fa-times ms-1"></i>
                          </button>
                        </div>

                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="emails" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <!--begin::Input group-->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label
                          class="form-label fs-6 fw-bolder text-dark required"
                        >
                          {{ $t("modals.pollster.poll") }}
                        </label>
                        <!--end::Label-->

                        <div class="d-flex align-items-center">
                          <Field
                            name="poll"
                            data-control="select1"
                            data-hide-search="true"
                            data-placeholder="Anket seçiniz..."
                            class="form-select form-select-lg form-select-solid"
                            as="select"
                            v-model="inviteUserModel.survey"
                            required
                          >
                            <option value="0" selected>
                              {{ $t("modals.pollster.selectPoll") }}
                            </option>
                            <option
                              v-for="(poll, index) in pollList"
                              :value="poll.poll.id"
                              :label="poll.poll.title"
                              :key="index"
                            >
                              {{ poll.poll.title }}
                            </option>
                          </Field>
                        </div>
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block">
                            <ErrorMessage name="poll" />
                          </div>
                        </div>
                      </div>
                      <!--end::Input group-->
                    </div>

                    <div class="d-flex justify-content-center">
                      <!--begin::Submit button-->
                      <button
                        type="submit"
                        id="kt_account_profile_details_submit"
                        ref="submitButton"
                        class="btn btn-primary"
                      >
                        <span class="indicator-label">
                          {{ $t("common.button.send") }}
                        </span>
                        <span class="indicator-progress">
                          {{ $t("common.pleaseWait") }}
                          <span
                            class="
                              spinner-border spinner-border-sm
                              align-middle
                              ms-2
                            "
                          ></span>
                        </span>
                      </button>
                      <!--end::Submit button-->
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
  <!--end::Dashboard-->
</template>

<style>
body.swal2-height-auto {
  height: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { PollListModel } from "@/domain/pollster/poll-list/model/PollListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { InviteUserModel } from "@/domain/pollster/Invite-user/model/InviteUserModel";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import i18n from "@/presentation/language/i18n";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const loading = ref<boolean>(false);
    const url = ref("");
    const swalNotification = new SwalNotification();

    const pollsterController = store.state.ControllersModule.pollsterController;
    const pollList = ref<PollListModel[]>([]);
    const originalPollList = ref<PollListModel[]>([]);

    const inviteUserModel = ref<InviteUserModel>({});

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const emailList = ref<string[]>([]);
    const emailInput = ref("");

    const form = Yup.object().shape({
      emails: Yup.string()
        .test("is-valid-email", t("validators_error.email"), (value) => {
          if (!value) return true; // Eğer boşsa kontrol etme
          const email = value.trim();
          return Yup.string().email().isValidSync(email); // Email'in geçerli olup olmadığını kontrol eder
        })
        .test("is-unique-email", t("validators_error.sameEmails"), (value) => {
          if (!value) return true; // Eğer boşsa kontrol etme
          const email = value.trim();
          return !emailList.value.includes(email); // Email'in emailList içinde olup olmadığını kontrol eder
        })
        .test(
          "required-if-no-emails",
          t("validators_error.required"),
          (value) => {
            // Eğer emailList boşsa, inputu zorunlu yapar
            if (emailList.value.length === 0 && !value) {
              return false;
            }
            return true;
          }
        ),
      poll: Yup.string().required(t("validators_error.required")).label("Type"),
    });

    const getPollList = async (page) => {
      pollList.value = [];
      originalPollList.value = [];

      loading.value = true;

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const pollListModel: PollListModel = {
        poll: {
          status: "ACTIVE",
        },
        page: page,
        pageSize: pageSize.value,
      };

      pollsterController
        .pollList(pollListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((poll) => {
              totalItems.value = poll.pagination.total;
              currentPage.value = poll.pagination.page;
              pageCount.value = poll.pagination.pageCount;
              pollList.value.push(poll);
              originalPollList.value.push(poll);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          loading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const inviteUser = async () => {
      if (emailInput.value) {
        emailList.value.push(emailInput.value);
      }
      inviteUserModel.value.emails = emailList.value;
      inviteUserModel.value.locale = i18n.global.locale.value;

      pollsterController
        .inviteUser(inviteUserModel.value)
        .then((response) => {
          if (response.isSuccess) {
            if (
              inviteUserModel.value &&
              inviteUserModel.value.emails &&
              inviteUserModel.value.emails.length > 1
            ) {
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_INVITE_USERS),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                heightAuto: false,
              }).then(() => {
                router.push({ name: "polls" });
              });
            } else {
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_INVITE_USER),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                heightAuto: false,
              }).then(() => {
                router.push({ name: "polls" });
              });
            }
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const handleEmailInput = (event) => {
      const value = event.target.value.trim();

      if (value.endsWith(",")) {
        const email = value.slice(0, -1).trim();

        if (email && !emailList.value.includes(email)) {
          emailList.value.push(email);
          emailInput.value = "";
        } else if (emailList.value.includes(email)) {
          emailInput.value = "";
        }
      }
    };

    const removeEmail = (index) => {
      emailList.value.splice(index, 1);
    };

    // const handleEmailInput = (event) => {
    //   const value = event.target.value;
    //   const emailArray = value.split(",").map((email) => email.trim());

    //   if (emailArray[emailArray.length - 1]) {
    //     emails.value = emailArray;
    //   }
    // };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPollList(1);
    });

    return {
      emailInput,
      handleEmailInput,
      removeEmail,
      emailList,
      form,
      url,
      pollList,
      inviteUserModel,
      inviteUser,
    };
  },
});
</script>
