
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { PollListModel } from "@/domain/pollster/poll-list/model/PollListModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { InviteUserModel } from "@/domain/pollster/Invite-user/model/InviteUserModel";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import i18n from "@/presentation/language/i18n";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const loading = ref<boolean>(false);
    const url = ref("");
    const swalNotification = new SwalNotification();

    const pollsterController = store.state.ControllersModule.pollsterController;
    const pollList = ref<PollListModel[]>([]);
    const originalPollList = ref<PollListModel[]>([]);

    const inviteUserModel = ref<InviteUserModel>({});

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const emailList = ref<string[]>([]);
    const emailInput = ref("");

    const form = Yup.object().shape({
      emails: Yup.string()
        .test("is-valid-email", t("validators_error.email"), (value) => {
          if (!value) return true; // Eğer boşsa kontrol etme
          const email = value.trim();
          return Yup.string().email().isValidSync(email); // Email'in geçerli olup olmadığını kontrol eder
        })
        .test("is-unique-email", t("validators_error.sameEmails"), (value) => {
          if (!value) return true; // Eğer boşsa kontrol etme
          const email = value.trim();
          return !emailList.value.includes(email); // Email'in emailList içinde olup olmadığını kontrol eder
        })
        .test(
          "required-if-no-emails",
          t("validators_error.required"),
          (value) => {
            // Eğer emailList boşsa, inputu zorunlu yapar
            if (emailList.value.length === 0 && !value) {
              return false;
            }
            return true;
          }
        ),
      poll: Yup.string().required(t("validators_error.required")).label("Type"),
    });

    const getPollList = async (page) => {
      pollList.value = [];
      originalPollList.value = [];

      loading.value = true;

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const pollListModel: PollListModel = {
        poll: {
          status: "ACTIVE",
        },
        page: page,
        pageSize: pageSize.value,
      };

      pollsterController
        .pollList(pollListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((poll) => {
              totalItems.value = poll.pagination.total;
              currentPage.value = poll.pagination.page;
              pageCount.value = poll.pagination.pageCount;
              pollList.value.push(poll);
              originalPollList.value.push(poll);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          loading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const inviteUser = async () => {
      if (emailInput.value) {
        emailList.value.push(emailInput.value);
      }
      inviteUserModel.value.emails = emailList.value;
      inviteUserModel.value.locale = i18n.global.locale.value;

      pollsterController
        .inviteUser(inviteUserModel.value)
        .then((response) => {
          if (response.isSuccess) {
            if (
              inviteUserModel.value &&
              inviteUserModel.value.emails &&
              inviteUserModel.value.emails.length > 1
            ) {
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_INVITE_USERS),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                heightAuto: false,
              }).then(() => {
                router.push({ name: "polls" });
              });
            } else {
              Swal.fire({
                text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_INVITE_USER),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("common.button.ok"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                heightAuto: false,
              }).then(() => {
                router.push({ name: "polls" });
              });
            }
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const handleEmailInput = (event) => {
      const value = event.target.value.trim();

      if (value.endsWith(",")) {
        const email = value.slice(0, -1).trim();

        if (email && !emailList.value.includes(email)) {
          emailList.value.push(email);
          emailInput.value = "";
        } else if (emailList.value.includes(email)) {
          emailInput.value = "";
        }
      }
    };

    const removeEmail = (index) => {
      emailList.value.splice(index, 1);
    };

    // const handleEmailInput = (event) => {
    //   const value = event.target.value;
    //   const emailArray = value.split(",").map((email) => email.trim());

    //   if (emailArray[emailArray.length - 1]) {
    //     emails.value = emailArray;
    //   }
    // };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPollList(1);
    });

    return {
      emailInput,
      handleEmailInput,
      removeEmail,
      emailList,
      form,
      url,
      pollList,
      inviteUserModel,
      inviteUser,
    };
  },
});
